.termsAndCondition {
  h4 {
    color: rgb(0, 0, 44);
  }
  p {
    color: rgb(75, 75, 75);
    font-size: 18px;
  }
  @media (max-width: 575px) {
    li {
      padding-right: 20px;
    }
  }
}
.footerItem {
  background: #ebeef6;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerSubItem {
  background: #ffffff;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 1px rgba(194, 194, 194, 0.607);
}
