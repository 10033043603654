.allCoursesBtn {
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 13px 30px;
  transition: 0.5s;
  background: rgb(0, 7, 69);
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.allCoursesBtn:hover {
  color: white;
}
.allCoursesBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 12, 143);
  z-index: -1;
  transform-origin: left;
  transition: transform 0.5s ease;
  transform: scaleX(1);
  border-radius: 5px !important;
}

.allCoursesBtn:hover::before {
  transform-origin: right;
  transition: transform 0.5s ease;
  transform: scaleX(0);
}

.card:hover {
  transition: all 0.6s ease;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.311);
}
.cardImg {
  height: 13rem;
  overflow: hidden;
  border-radius: 7px;
}
.cardImg {
  height: 14rem;
  overflow: hidden;
  border-radius: 7px;
}
#card:hover {
  .courseIcon {
    transform: rotate(-35deg);
    transition: all 0.5s ease;
  }
  img:first-child {
    transform: scale(1.1);
  }
}

.cardImg img {
  width: 100%;
  height: 100%;
  height: auto !important;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}

.pastPrice {
  color: gray;
}
.courseInstructor {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.Instructor {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media (max-width: 575px) {
  .heroFooter {
    display: none;
  }
  .courseDetails {
    flex-direction: column;
    align-items: start !important;
  }
  .cardImg {
    height: 12rem;
    width: 100%;
  }
  .allCoursesBtn {
    z-index: 1;
    padding: 5px 20px;
    transition: 0.5s;
    background: rgb(0, 7, 69);
    color: rgb(255, 255, 255);
    text-decoration: none;
    border-radius: 5px;
  }
}
.instructorName {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .instructorName {
  opacity: 1;
}
.courseInstructor {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .courseInstructor {
  opacity: 1;
}

@media (max-width: 575px) {
  .courseInstructor,
  .instructorName {
    opacity: 1;
  }
}
