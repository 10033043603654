.navbar {
  background: #f8f0ff;
  color: rgb(40, 1, 83);
  position: sticky;
  top: 0;
  z-index: 999;

  .navLinks {
    text-decoration: none;
    color: rgb(40, 1, 83);
    font-weight: 400;
  }
  .navbar-nav {
    font-family: "Tiro Bangla", roboto !important;
  }
  .btn2 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 4px 9px;
    border: 0;
    transition: 0.5s;
    background: rgb(0, 12, 143);
    color: rgb(0, 12, 143);
    text-decoration: none;
  }
  .btn2:hover {
    color: white;
    border-radius: 5px;
  }
  .btn2::before {
    content: "";
    position: absolute;
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f8f0ff;
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .btn2:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  .btn1 {
    position: relative;
    z-index: 1;
    padding: 7px 8px;
    transition: 0.5s;
    background: rgb(0, 7, 69);
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  .btn1:hover {
    color: white;
    border-radius: 5px;
  }
  .course:hover {
    transform: translateY(-5px);
  }
  .active {
    background: rgb(0, 12, 143) !important;
    color: white !important;
    border-radius: 5px;
  }
  .btn1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 12, 143);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }
  .active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 12, 143);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .btn1:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
}
.link {
  text-decoration: none !important;
}
.nav-item {
  padding: 0 5px;
  font-family: "Tiro Bangla", roboto !important;
}
/* @media (max-width: 1400px) {
  .nav-item {
    padding: 0 5px !important;
  }
} */
.navbar-toggler:focus {
  outline: none !important;
  border: 0;
  box-shadow: none;
}
@media (max-width: 575px) {
  .navbar-toggler {
    width: 15%;
    text-align: end;
    margin-top: 1rem;
    background: transparent;
    border: none;
    font-size: 30px;
    color: rgb(2, 0, 136);
  }

  .navImg {
    width: 70%;
    justify-content: start !important;
    padding-left: 2rem;
  }
  .navImg img {
    width: 45% !important;
  }
  .navbar-nav {
    margin-top: 2rem;
    gap: 10px !important;
  }
  .nav-item {
    padding: 0 10px;
  }
  .nav-item:last-child {
    margin-bottom: 10px;
  }
  .btnGRP {
    flex-direction: column;
    width: 100%;
    align-items: start !important;
  }
  .btnGRP a {
    padding: 20px 0;
    font-size: 17px !important;
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .course {
    font-size: 16px !important;
  }
}
.dropdown-menu {
  width: 13rem;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  right: 4px !important;
}
