.blog h5 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(0, 0, 86);
}
.btn3 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 5px 9px;
  border: 0;
  transition: 0.5s;
  background: rgb(0, 12, 143);
  color: rgb(255, 255, 255);
  text-decoration: none;
  border-radius: 5px;
}
.btn3:hover {
  color: white;
}
.btn3::before {
  content: "";
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #0763ee;
  z-index: -1;
  transform-origin: left;
  transition: transform 0.5s ease;
  transform: scaleX(1);
}

.btn3:hover::before {
  transform-origin: right;
  transition: transform 0.5s ease;
  transform: scaleX(0);
}
.btn3:hover {
  color: white;
  border-radius: 5px;
  i {
    transform: rotate(-180deg);
    transition: all 0.5s ease;
  }
}

@media (max-width: 575px) {
  .blogDetail {
    flex-direction: column;
  }
  .blogImgs img {
    width: 100% !important;
    height: 100% !important;
  }
  .blogImgs .col-lg-12 {
    margin-left: 0 !important;
  }
  .blogImgs {
    margin-top: 30px;
  }
}
.blogmiddleImg {
  height: 5rem !important;
  width: 8rem !important;
}
