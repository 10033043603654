.editor {
  background: #abbbdf;
  color: white;
  padding: 20px 0;
  border-radius: 5px;
}

.editor textarea {
  width: 100%;
  height: 25rem;
  background: #121526;
  color: white;
  padding: 10px 20px;
  border: 0;
  outline: 0;
  font-size: 18px;
  border-radius: 5px;
}
.editor iframe {
  width: 100%;
  height: 25rem;
  background: #ffffff;
  color: rgb(0, 0, 0);
  border: 0;
  outline: 0;
  border-radius: 5px;
}
.editorTitle {
  background: #00012e;
  border-radius: 5px;
}
.editor label {
  display: flex;
  align-items: center;
  background: #00012e;
  height: 35px;
}
.htmlIcon {
  color: #ff8f17;
}
.cssIcon {
  color: #64a5ff;
}
.jsIcon {
  color: #ffea00;
}
@media (max-width: 575px) {
  .right {
    margin-top: 10px;
  }
}

.editorTitle label.active {
  border: 1px solid rgb(170, 170, 170);
  border-top: 0;
  border-bottom: 0;
}
