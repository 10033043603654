.newsletter {
  background: #e8f0fe;
  border-top: 1px solid #e1ecff;

  h3 {
    color: rgb(0, 7, 69);
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
  }
  p {
    color: rgb(94, 94, 94);
    line-height: 1.4;
  }
  form {
    background: #fff;
    border: 1px solid #e1ecff;
    border-radius: 4px;
    margin-top: 20px;
    padding: 6px 10px;
    /* position: relative; */
    width: 45%;
  }
  .newsInput {
    border: 0;
    padding: 8px;
    width: 100%;
  }
}
input:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}
@media (max-width: 575px) {
  .newsletter h3 {
    font-size: 20px;
  }
  .newsletter form {
    width: 90%;
    button {
      padding: 2px 10px;
      font-size: 12px !important;
    }
  }
}
