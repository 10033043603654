.hero {
  width: 100%;
  background: url(../../../src/assets/startup-hero-03.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .heroFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .heroTitle {
    font-family: "Hind Siliguri";
  }

  .img1 {
    position: absolute;
    left: 0;
    top: 8rem;

    @media (max-width: 575px) {
      display: none;
    }
  }
  .img2 {
    position: absolute;
    right: 5rem;
    top: 8rem;
    @media (max-width: 991px) {
      right: 2rem;
      top: 8rem;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
  .img3 {
    position: absolute;
    right: 40rem;
    top: 4rem;
    @media (max-width: 767px) {
      right: 30rem;
      top: 0.4rem;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }

  h1 {
    color: rgb(69, 0, 143);
    font-weight: 700;
    font-size: 3rem;
    @media (max-width: 575px) {
      font-size: 2rem;
      text-align: center;
    }
  }

  .btn-grp {
    height: 60px;
  }

  .desc {
    color: rgb(52, 52, 52);
    text-align: justify;
  }
  .title {
    position: relative;
    width: 63%;
    text-align: right;
    &:hover {
      &::after {
        left: 70px;
        top: 8px;
        transition: all 0.7s ease;
      }
    }
  }

  .title::before {
    content: "";
    height: 4px;
    width: 80px;
    background: rgb(69, 0, 143);
    position: absolute;
    left: 2px;
    top: 11px;
  }
  .title::after {
    content: "";
    height: 13px;
    width: 13px;
    background: rgb(69, 0, 143);
    position: absolute;
    left: 3px;
    top: 7px;
    transform: rotate(45deg);
  }
  @media (max-width: 991px) {
    .title {
      display: none;
    }
  }
  @media (max-width: 1400px) {
    .title {
      width: 72%;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 575px) {
    .title {
      display: block;
      font-size: 15px !important;
      text-align: center;
      width: 100%;
      margin-bottom: 0 !important;
    }
    .title::after {
      content: "";
      height: 0 !important;
      width: 0 !important;
    }
    .title::before {
      content: "";
      height: 0 !important;
      width: 0 !important;
    }

    .fbBtn {
      margin-right: 0 !important;
      display: block;
    }

    h1 {
      font-size: 6rem;
    }
    .heroFooter {
      display: none;
    }
    .cont {
      padding: 1rem 1rem !important;
      h1 {
        font-size: 26px;
      }
      .shortIcon {
        p {
          font-size: 18px;
          gap: 5px !important;
        }
      }
      .desc {
        text-align: justify;
      }
      .btn-grp {
        flex-direction: column;
        margin-bottom: 40px;
      }
      .fbBtn,
      .fbBtn2 {
        margin-right: 5px 10px !important;
        margin-bottom: 10px;
      }
    }
  }

  .cont {
    padding: 8rem 0;
  }
  .cont:hover {
    p::after {
      left: 70px;
      top: 5px;
      transition: all 2s ease;
    }
  }
}
.animated1 {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
.animated2 {
  animation: up-down 1.6s ease-in-out infinite alternate-reverse both;
}

.animated3 {
  animation: up-down 2.3s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
