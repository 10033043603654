.contact {
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8),
      rgba(226, 222, 250, 0.8)
    ),
    url(../../assets/bg3.png) no-repeat;
  background-size: cover;
}
.sub-form {
  color: #444;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  background: #e6ebff;
  text-decoration: none;
}

.sub-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f1f3ff;
  z-index: -1;
  transform-origin: left;
  transition: transform 0.5s ease;
  transform: scaleX(1);
}

.sub-form:hover::before {
  transform-origin: right;
  transition: transform 0.5s ease;
  transform: scaleX(0);
}
.sub-form i {
  color: #4154f1;
  font-size: 2.5rem;
  padding: 5px 0;
}
.sub-form h3 {
  color: #012970;
  font-size: 25px;
  font-weight: 700;
  padding: 10px 0;
}
.sub-form p {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 0;
}

.sendBtn {
  position: relative;
  z-index: 1;
  width: 40%;
  padding: 13px 30px;
  transition: 0.5s;
  background: rgb(0, 7, 69);
  color: rgb(255, 255, 255);
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    color: white;
    i {
      transform: rotate(-35deg);
      transition: all 0.5s ease;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 12, 143);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
    border-radius: 5px;
  }
  &:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .left-form {
    margin-bottom: 30px;
    .contactRow {
      justify-content: center;
    }
  }
  .sub-form {
    text-align: center;
    p {
      text-align: center;
    }
  }
}
.form-control:focus {
  outline: none !important;
  border: 0;
  box-shadow: none;
}
.selected-Info {
  border: none;
  background: white;
  border-radius: 5px;
  padding: 0.4rem 5.2rem;
  @media (max-width: 575px) {
    width: 100%;
  }
}
@media (max-width: 1400px) {
  .selected-Info {
    padding: 0.4rem 3.8rem;
  }
  .sendBtn {
    width: 100%;
    font-size: 18px !important;
    padding: 5px 0;
  }
}
