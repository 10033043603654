.gallery {
  width: 20rem;
  margin: 5px;
}
img {
  width: 100%;
  height: 100%;
  height: auto !important;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}
.gallery1 {
  height: 13rem;
  overflow: hidden;
  border-radius: 7px;
  &:hover img {
    transform: scale(1.2);
  }
}
