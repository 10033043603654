.skill {
  background: url(../../assets/Frame-427319458.png);
  .skillCard1 {
    box-shadow: 0px 0px 2px 0px rgba(142, 142, 142, 0.7);
  }

  @media (max-width: 575px) {
    .skillCard {
      display: flex;
      justify-content: center;
      .card {
        text-align: center;
        img {
          width: 50%;
          margin: auto;
        }
      }
    }
  }
  .skillCard1 {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    border-bottom: 3px solid rgb(255, 181, 7);
    text-decoration: none;
  }

  .skillCard1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid rgb(255, 181, 7);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .skillCard1:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  .skillCard2 {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    border-bottom: 3px solid rgb(65, 7, 255);
    text-decoration: none;
  }
  .banner {
    width: 100%;
    border-radius: 0 !important;
  }
  .banner2 {
    width: 100%;
    border-radius: 0 !important;
  }
  .skillCard2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid rgb(65, 7, 255);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .skillCard2:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }

  .skillCard3 {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    border-bottom: 3px solid rgb(255, 57, 146);
    text-decoration: none;
  }

  .skillCard3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid rgb(255, 57, 146);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .skillCard3:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  .skillCard4 {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    border-bottom: 3px solid rgb(234, 4, 255);
    text-decoration: none;
  }

  .skillCard4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid rgb(234, 4, 255);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .skillCard4:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  .card:hover {
    transition: all 0.5s ease;
    transform: translateY(-12px);
    a {
      border: none;
    }
    i {
      transform: rotate(-25deg);
      transition: all 0.5s ease;
    }
  }

  .card a {
    position: relative;
    z-index: 1;
    padding: 5px 10px;
    transition: 0.5s;
    background: rgb(0, 7, 69);
    color: rgb(0, 7, 69);
    text-decoration: none;
  }
  .card a:hover {
    color: rgb(254, 254, 255);
    border-radius: 5px;

    i {
      transform: rotate(-35deg);
      transition: all 0.5s ease;
    }
  }
  .card a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }

  .card a:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
}
@media (max-width: 1400px) {
  .skill h2 {
    font-size: 23px;
  }
}
.banner2 {
  display: none;
}

@media (max-width: 575px) {
  .banner {
    display: none;
  }
  .banner2 {
    display: block !important;
  }
}
